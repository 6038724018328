import React from "react"
import 'bootstrap/dist/css/bootstrap.min.css'

//Components
import Layout from "../components/layout"
// import SEO from "../components/seo"
import Service from "../components/service"
import Team from "../components/team"
import Contact from "../components/findus"
import Home from "../components/home"

// //DataServices
// import {useServiceDataService} from '../hooks/service-data'
// import {useNewsDataService} from '../hooks/news-data'
// import {useContactDataService} from '../hooks/contact-data'
//import {useNewsDataService} from '../hooks/news-data'

const IndexPage = () => (
  <Layout>
    <Home id="home"/>
    <Service id="service"/>
    <Team id="team"/>
    <Contact id="contact"/>
  </Layout>
)

export default IndexPage
