import React from 'react';
import {Image, Container, Row, Col} from 'react-bootstrap';
import SectionHeader from "../components/section-header"
import { graphql, useStaticQuery } from 'gatsby';
import SEO from '../components/seo';

const Team = (props) => {

    const data = useStaticQuery(graphql`
        query {
            prismicHomepage {
                data {
                    team_member {
                        first_name {
                            text
                        }
                        job_titel {
                            text
                        }
                        last_name {
                            text
                        }
                        portrait {
                            url
                        }
                        title {
                            text
                        }
                    }
                    titel_team {
                        text
                    }
                    description_team {
                        html
                    }
                }
            }
        }
    `)

    const id = "team"
    const titel = data.prismicHomepage.data.titel_team.text
    const description = data.prismicHomepage.data.description_team.html
    const teamMemberList = data.prismicHomepage.data.team_member

    return( 
      <>
            <SEO title={titel} /> 
            <Container id={id}>
                <SectionHeader titel={titel} description={description}/>
                <Row className="justify-content-md-center">
                    {teamMemberList.map((member,index) => (
                        <Col md={4} className="team-member" key={index}>
                            <TeamMember  member={member}/>    
                        </Col>
                    ))}                
                </Row>                
            </Container>
        </>
    )
}

const TeamMember = (props) => {

    const person = props.member
    const url = person.portrait.url
    const title = person.title.text
    const first_name = person.first_name.text
    const last_name = person.last_name.text
    const job_titel = person.job_titel.text

    return(
        <div>
            <Image className="person-portrait" src={url} roundedCircle />
            <h3 className="person-titel">{title} {first_name} {last_name}</h3>
            <div className="job-titel">{job_titel}</div>
        </div>
    )
}

export default Team

